import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { OvalAnchor } from "../elements/buttons.js"

import { useForm } from "react-hook-form"
import { Input } from "./step1.js"

// BUTTON CSS
const SubmitButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  width: 4em;
  padding: 3px 2em;
  text-align: center;
  display: block;
  margin: 1em;
`
//FOOTER
const SubmitNext = styled(SubmitButton)`
  &::after {
    content: "  ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
    position: relative;
    left: 5px;
    top: -1px;
  }
  &:hover {
    &::after {
      transform: rotate(-45deg) translate(3px, 3px);
    }
  }
`
const SubmitBack = styled(SubmitButton)`
  &::before {
    content: " ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    position: relative;
    right: 5px;
    top: -1px;
  }
  &:hover {
    &::before {
      transform: rotate(135deg) translate(-3px, -3px);
    }
  }
`
const ButtonWrapper = styled.footer`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 90vw;
  height: auto;
  margin: 0 auto;
  padding-top: 100px;
`
//ASIDE

//FORM
const FormWrapper = styled.form`
  background-color: white;
  display: block;
  width: 50%;
  top: 0;
  height: 100%;
  min-height: 100vh;

  align-items: stretch;
  margin: 0 auto 0 0;
`

const Content = styled.div`
  padding: 0 24px;
  width: 100%;
`

const OptionWrapper = styled.label`
  width: 90%;
  height: 40px;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  border-radius: 10px;
  border: 1px solid black;
  margin: 1em 0;
  align-items: center;
  cursor: pointer;
`

const RadioInput = styled(Input).attrs({
  type: "radio",
})`
  width: 1em;
  height: 35px;
  margin: 0;
  margin-right: 1em;
`
const Hr = styled.hr`
  width: 100%;
  margin: 15px auto;
  float: left;
  display: block;
`

//background page (done)
//Add SVG
//front white page on left - responsive (done but not responseive)
// bill on the right. (done)
// Questions on the left.
// buttons on the bottom for navigation (done)

const ServiceDetails = ({
  courseInfo,
  handleNext,
  handleBack,
  discounts,
  updateDiscounts,
}) => {
  const [haveIncentives, setHaveIncentives] = useState(null)
  const [submitType, setSubmit] = useState("next")

  const { register, handleSubmit } = useForm({
    mode: "onBlur",
  })
  const onSubmit = submitData => {
    updateDiscounts(submitData)
    if (submitType === "next") {
      handleNext()
    } else {
      handleBack()
    }
  }

  useEffect(() => {
    if (haveIncentives === null) {
      courseInfo.forEach((course, index) => {
        if (course.incentiveQuestions.length > 0) {
          setHaveIncentives(true)
        }
      })
    }
  }, [])

  return (
    <Wrapper>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        {!haveIncentives
          ? null
          : courseInfo.map((course, index) =>
              course.incentiveQuestions.map((question, qsIndex) => (
                <Content key={`${course.serviceName}${index}qs${qsIndex}`}>
                  <header>
                    <h4>
                      {index + qsIndex + 1}. {course.serviceName} Incentive:{" "}
                      {question["line-item"]}
                    </h4>
                    <Hr />
                    <div>{question.question}</div>
                  </header>
                  {question.options.map((qs, i) => (
                    <OptionWrapper key={`${index}-${i}`}>
                      <RadioInput
                        name={`${index}-${qsIndex}`}
                        value={i}
                        ref={register}
                        // onChange={() => {
                        //   handleRadioChange(index, qsIndex, i)
                        // }}
                      />
                      {qs}
                    </OptionWrapper>
                  ))}
                </Content>
              ))
            )}
        <ButtonWrapper>
          <SubmitBack
            type="submit"
            backgroundColor={"#F50057"}
            onClick={() => {
              setSubmit("back")
            }}
          >
            Back
          </SubmitBack>
          <SubmitNext
            type="submit"
            backgroundColor={"#F50057"}
            onClick={() => {
              setSubmit("next")
            }}
          >
            Next
          </SubmitNext>
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  )
}

export default ServiceDetails

{
  /* <Content>
          <header>
            <h4>1. Accountability Incentives</h4>
            <Hr />
            <div>
              How many hours are you willing to study each day
              <em> 6 days a week</em>?
            </div>
          </header>

          <OptionWrapper>
            <RadioInput name="incentive1" value="6" />6 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="7" />7 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="8" />8 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="8" />9 hours, 5 days a week.
          </OptionWrapper>
        </Content>
        <Content>
          <h4>2. Deadline Incentive</h4>
          <hr></hr>
          How many hours are you willing to study each day
          <em> 6 days a week</em>?
          <OptionWrapper>
            <RadioInput name="incentive1" value="6" />6 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="7" />7 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="8" />8 hours, 5 days a week.
          </OptionWrapper>
          <OptionWrapper>
            <RadioInput name="incentive1" value="8" />9 hours, 5 days a week.
          </OptionWrapper>
        </Content> */
}
