export const allFields = [
  {
    school: "University of Toronto",
    belongsTo: "employment",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "Level of Education",
    label: "Education",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },

  {
    school: "University of Toronto",
    belongsTo: "employment",
    type: "text",
    limit: null,
    limitType: null,
    placeholder: "How much (in hours) did you do it?",
    label: "Hours",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "employment",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "When did you do it",
    label: "Duration",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "employment",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "What did you do there?",
    label: "Responsibilities",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "employment",
    type: "checkbox",
    limit: null,
    limitType: null,
    placeholder: "Do you think it's significant?",
    label:
      "Most important activities (top 3 per category) for Ottawa (max. 18 in total).",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "volunteering",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "In which year did you work happen in?",
    label: "Level of education",
    example: "first year of university",
    explanation: "",

    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "volunteering",
    type: "text",
    limit: 60,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Hours",
    example: "",
    explanation: "",

    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "volunteering",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "When did you do it??",
    label: "Summer, Academic Year, Before University, or After Graduation",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "volunteering",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Responsibilities",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "specific_activity_detail",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "In which year did you work happen in?",
    label: "Level of education",
    example: "first year of university",
    explanation: "",

    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "specific_activity_detail",
    type: "text",
    limit: 60,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Hours",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "specific_activity_detail",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "When did you do it??",
    label: "Summer, Academic Year, Before University, or After Graduation",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "specific_activity_detail",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Responsibilities",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "extra_curriculars",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "In which year did you work happen in?",
    label: "Level of education",
    example: "first year of university",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "extra_curriculars",
    type: "text",
    limit: 60,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Hours",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "extra_curriculars",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "When did you do it??",
    label: "Summer, Academic Year, Before University, or After Graduation",
    example: "",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "extra_curriculars",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "How much did you work?",
    label: "Type of Activity/Level of Performance",
    example: "",
    explanation: "",

    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "awards_and_accomplishments",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "Date Received",
    label: "Date Received",
    example: "first year of university",
    explanation: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "awards_and_accomplishments",
    type: "text",
    limit: 60,
    limitType: "chars",
    placeholder: "How long?",
    label: "Duration",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "awards_and_accomplishments",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "What did you do?",
    label: "Qualifications",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "awards_and_accomplishments",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "Who did you compete with?",
    label: "Competition Involved",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "research",
    type: "text",
    limit: 30,
    limitType: "chars",
    placeholder: "I was in...",
    label: "Field Level of Education",
    example: "first year of university",
    explanation: "",

    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "research",
    type: "text",
    limit: 60,
    limitType: "chars",
    placeholder: "What was it?",
    label: "Project Title",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "research",
    type: "text",
    limit: 100,
    limitType: "chars",
    placeholder: "What did you do?",
    label: "Type of Publication",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "research",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "tell 'em what you did.",
    label: "My role",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
  {
    school: "University of Toronto",
    belongsTo: "other",
    type: "text",
    limit: 150,
    limitType: "chars",
    placeholder: "The unique or different thing I did was...",
    label: "My role",
    explanation: "",
    example: "",
    rules: { required: "You missed this field", minLength: 0 },
    errorMessage: "This field is required",
  },
]
