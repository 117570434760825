import React, { useRef, useEffect } from "react"
import styled from "styled-components"

const TextArea = styled.textarea`
  outline: none;
  border: ${props => (props.border ? props.border : "none")};
  padding: ${props => (props.padding ? props.padding : "none")};
  outline: ${props => (props.outline ? props.outline : "none")};
  background: ${props => (props.background ? props.background : "transparent")};
  color: ${props => (props.color ? props.color : "black")};
  font-size: ${props => (props.fontSize ? props.fontSize : "inherit")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : "400")};
  font-family: ${props => (props.fontFamily ? props.fontFamily : "inherit")};
  font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
  width: ${props => (props.width ? props.width : "100%")};
  margin: ${props => (props.margin ? props.margin : "16px 0")};
  line-height: 1.58;
  letter-spacing: -0.003em;
`

const AutoHeightTextArea = ({ onChange, autoSave, ...rest }) => {
  //SEE Styled TextArea component for properties! IMPORTANT
  const textAreaRef = useRef(null)

  const updateHeight = el => {
    adjustIfContentDeleted()
    adjustIfContentAdded()
    return null
  }
  const adjustIfContentAdded = () => {
    let newHeight = `${textAreaRef.current.scrollHeight}px`
    if (newHeight !== textAreaRef.current.style.height) {
      textAreaRef.current.style.height = newHeight
      textAreaRef.current.style["overflow-y"] = "hidden"
      //insert auto save here.
    }
    return null
  }
  const adjustIfContentDeleted = () => {
    let prevHeight = textAreaRef.current.style.height
    textAreaRef.current.style.height = "auto"
    let currentHeight = textAreaRef.current.style.height
    if (prevHeight !== currentHeight) {
      textAreaRef.current.style.height = currentHeight
      //insert auto save here
    }
    return null
  }
  const onChangeHandler = e => {
    updateHeight()
    if (typeof onChange === "function") {
      onChange(e)
    }
  }
  return (
    <TextArea
      {...rest}
      ref={textAreaRef}
      onChange={e => {
        onChangeHandler(e)
      }}
      row={1}
    />
  )
}

export default AutoHeightTextArea
