import React from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Profile from "../components/authSections/profile.js"
import Login from "../components/authSections/login"
import Signup from "../components/authSections/signup"
import PrivateRoute from "../components/authSections/privateRoute.js"
import Checkout from "../components/authSections/checkout.js"

import Admin from "../components/authSections/adminPages/view.js"
import StudentDashboard from "../components/authSections/studentPages/dashboard.js"
import StudentLayout from "../components/authSections/studentPages/studentLayout.js"
import Abs from "../components/authSections/studentPages/abs.js"
import ApplicationsView from "../components/authSections/studentPages/applicationView.js"
import Essay from "../components/authSections/studentPages/essay.js"
import Courses from "../components/authSections/studentPages/courses.js"
// App component logic:
// Check whether use
// const App = props => {
//   let appPath = props.location.pathname //URL typed by user.
//   appPath = appPath.substring(5) //Eliminates "/app/" part from pathname.
//   console.log(appPath)
//   //UserPaths ==> Accessible by Student Login. **ADD NEW PATHS HERE**
//   const userPages = ["profile", "checkout", "signup", "login"]
//   const newUserPages = [
//     "abs_entries",
//     "essays",
//     "dashboard",
//     "response",
//     "courses",
//     "response/",
//   ]
//   let isUserPage = userPages.includes(appPath)
//   let isNewUserPage = newUserPages.includes(appPath)
//   //AdminPaths: Must start with /app/__ (2 underscores)
//   let isAdminPage = appPath[0] === "_" && appPath[1] !== "_"

//   if (isAdminPage) {
//     return (
//       <Layout>
//         <Router>
//           <AdminRoute path="/app/__admin" component={AdminDash} />
//           <AdminLogin path="/app/__login" />
//         </Router>
//       </Layout>
//     )
//   } else if (isUserPage) {
//     console.log("REACHED HERE")
//     return (
//       <Layout>
//         <Router>
//           <PrivateRoute path="/app/checkout" component={Checkout} />
//           <PrivateRoute path="/app/profile" component={Profile} default />
//           <Signup path="/app/signup" />
//           <Login path="/app/login" />
//         </Router>
//       </Layout>
//     )
//   } else if (isNewUserPage) {
//     console.log("IS DEF NEW USER PAGE")
//     return (
//       <Router>
//         <PrivateRoute
//           path="/app/"
//           appPath={props.location.pathname}
//           component={StudentLayout}
//         >
//           <Abs path="/abs_entries" default />
//           <ApplicationsView path="/essays" />
//           <Essay path="/response" />
//           <Courses path="/courses" />
//         </PrivateRoute>
//       </Router>
//     )
//   } else {
//     if (
//       !isUserPage &&
//       !isAdminPage &&
//       !isNewUserPage &&
//       typeof window !== "undefined"
//     ) {
//       console.log("issue")
//       navigate("/404")
//       return null
//     }
//   }
// }
const LayoutSelector = ({ component: Component, children, legacy, ...rest }) =>
  legacy === true ? (
    <Layout {...rest}>
      <Component {...rest} />
    </Layout>
  ) : (
    <StudentLayout {...rest}>
      <Component {...rest} />
    </StudentLayout>
  )
const App = props => {
  return (
    <>
      <Router>
        <PrivateRoute
          path="/app/checkout"
          appPath={props.location.pathname}
          component={Checkout}
          legacy={true}
        />
        <PrivateRoute
          path="/app/profile"
          appPath={props.location.pathname}
          component={Profile}
          legacy={false}
        />
        <PrivateRoute
          path="/app/abs_entries"
          appPath={props.location.pathname}
          component={Abs}
          legacy={false}
        />
        <PrivateRoute
          path="/app/abs"
          appPath={props.location.pathname}
          component={Abs}
          legacy={false}
        />
        <PrivateRoute
          path="/app/essays"
          appPath={props.location.pathname}
          component={ApplicationsView}
          legacy={false}
        />
        <PrivateRoute
          path="/app/response/"
          appPath={props.location.pathname}
          component={Essay}
          legacy={false}
        />
        <PrivateRoute
          path="/app/response/:userid"
          appPath={props.location.pathname}
          component={Essay}
          legacy={false}
        />

        <PrivateRoute
          path="/app/courses"
          appPath={props.location.pathname}
          component={Courses}
          legacy={false}
        />
        <PrivateRoute
          path="/app/dashboard"
          appPath={props.location.pathname}
          component={StudentDashboard}
          legacy={false}
        />
        <PrivateRoute
          path="/app/__admin/"
          appPath={props.location.pathname}
          component={Admin}
          legacy={false}
        />
        <Signup path="/app/signup" />
        <Login path="/app/login" />
      </Router>
    </>
  )
}

export default App
