import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { loadStripe } from "@stripe/stripe-js/pure"

import PaymentSteps from "../checkout/stepper.js"
import AccountStep from "../checkout/step1.js"
import Bill from "../checkout/bill.js"
import ServiceDetails from "../checkout/step2.js"

import {
  fetchStripe,
  checkoutMultiGetCourseInfo,
  sendCheckoutStep1Form,
} from "../../services/apiRequests.js"

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_e5ysjmA4BTHpPpnwOrbB1dGd"
    : "pk_test_t0wDGDr5oNtXlBdEu3jykxr5"
)

const CheckoutWrap = styled.div`
  width: 100%;
  min-height: 3em;
  height: auto;
`
const Checkout = ({ path }) => {
  const [stage, setStage] = useState(0)
  const [currStep, setStep] = useState(0)
  const [step1FormData, setStep1Data] = useState({})
  const [step2FormData, setStep2Data] = useState({})
  const [step3FormData, setStep3Data] = useState({})
  const [courseInfo, setCourseInfo] = useState(null)

  const handleNext = (e, data) => {
    if (e === 0) {
      setStep1Data(data)
    } else if (e === 1) {
      setStep2Data(data)
    } else if (e === 2) {
      setStep3Data(data)
    }
    if (stage === currStep) {
      setStage(stage + 1)
    }
    setStep(currStep + 1)
  }
  const handleBack = e => {
    if (currStep > 0) {
      setStep(currStep - 1)
    }
  }
  const handleStepper = num => {
    if (num <= stage) {
      setStep(num)
    }
  }

  const handleStep1 = data => {
    let payload = Object.assign({}, data)
    sendCheckoutStep1Form(payload)
  }
  const handleStep2 = string => {
    setStep2Data(string)
  }
  const handleStripeRedirect = async session => {
    const stripe = await stripePromise
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    })
  }
  const handleConfirmation = () => {
    fetchStripe(step1FormData, step2FormData, courseInfo, handleStripeRedirect)
  }

  useEffect(() => {
    if (window && courseInfo === null) {
      let query =
        localStorage.getItem("__buy") !== null
          ? JSON.parse(localStorage.getItem("__buy"))
          : undefined
      let fetchInfo = checkoutMultiGetCourseInfo(query, data => {
        if (typeof data === "object") {
          setCourseInfo(data)
        }
      })
    }
  }, [step1FormData, step2FormData, setStep2Data, setStep1Data])

  return (
    <CheckoutWrap>
      <PaymentSteps stage={stage} callback={handleStepper} />
      {currStep === 0 ? (
        <AccountStep
          handleNext={handleNext}
          handleFormSubmit={handleStep1}
          formData={step1FormData}
        />
      ) : currStep === 1 ? (
        <ServiceDetails
          courseInfo={courseInfo}
          handleNext={handleNext}
          handleBack={handleBack}
          discounts={step2FormData}
          updateDiscounts={handleStep2}
        />
      ) : currStep === 2 ? (
        <Bill
          itemsArray={courseInfo}
          discountObj={step2FormData}
          handleNext={handleConfirmation}
          handleBack={handleBack}
        />
      ) : (
        <div />
      )}
    </CheckoutWrap>
  )
}

export default Checkout
