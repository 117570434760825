import React, { useState } from "react"
import styled from "styled-components"
import { OvalAnchor } from "../elements/buttons.js"

const WrapperWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 90vw;
  height: auto;
  margin: 0 auto;
  padding-top: 100px;
`
const Wrapper = styled.div`
  height: auto;
  padding: 10px;
  border-radius: 20px;
  width: 35%;
  margin: 0 20px;
  z-index: 2;
  background: black;
  margin: 0 auto;
`

const Title = styled.h2`
  color: white;
  padding: 0 15px;
`
const Flex = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  height: auto;
`
const Item = styled.div`
  width: 100%;
  padding: 15px 10px;
  display: flex;
  flex-flow: row nowrap;
  height: auto;
  align-content: center;
`

const Icon = styled.div`
  width: 18%;
  height: 52px;
  border-radius: 20px;
  background-color: blue;
  padding: 10px;
  font-size: 40px;
  color: white;
  text-align: center;
  font-weight: 800;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 85%;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
`

//ITEM HEADING
const ItemName = styled.h4`
  display: block;
  color: rgb(255, 255, 255, 0.8);
  margin: 10px;
`
const Price = styled(ItemName)`
  display: block;
  margin-right: 0;
  &::before {
    content: "$";
  }
`

//BREAKDOWN CSS
const Description = styled.small`
  color: rgb(255, 255, 255, 0.5);
  position: relative;
  margin-right: 10px;
  left: 10px;
`
// const DescripPrice = styled(Description)`
//   &::before {
//     content: "  $ ";
//   }
// `
const Discount = styled(Description)`
  color: green;
  transition: 0.5s all ease-in-out;
  &::before {
    content: "- $ ";
  }
`

//Button CSS
const SubmitButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  width: 4em;
  padding: 3px 2em;
  text-align: center;
  display: block;
  margin: 1em;
`
//FOOTER
const SubmitNext = styled(SubmitButton)`
  &::after {
    content: "  ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
    position: relative;
    left: 5px;
    top: -1px;
  }
  &:hover {
    &::after {
      transform: rotate(-45deg) translate(3px, 3px);
    }
  }
`
const SubmitBack = styled(SubmitButton)`
  &::before {
    content: " ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    position: relative;
    right: 5px;
    top: -1px;
  }
  &:hover {
    &::before {
      transform: rotate(135deg) translate(-3px, -3px);
    }
  }
`
const ButtonWrapper = styled.footer`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`

const Bill = ({ itemsArray, discountObj, handleNext, handleBack }) => {
  return (
    <WrapperWrap>
      <Wrapper>
        <header>
          <Title>Basket Summary</Title>
          <hr />
        </header>
        <Flex>
          {itemsArray &&
            itemsArray.map((service, index) => {
              return (
                <Item key={service.serviceName + "item" + index}>
                  <Icon>{service.serviceName[0].toUpperCase()}</Icon>
                  <Flex>
                    <Row>
                      <ItemName>{service.serviceName} (Pre-Tax)</ItemName>
                      <Price>{service.servicePrice}</Price>
                    </Row>
                    {service.incentiveQuestions.map((question, qsIndex) => {
                      let discountInd = discountObj[`${index}-${qsIndex}`]
                      return (
                        <Row key={`${index}-${qsIndex}-row`}>
                          <Description>{question["line-item"]}</Description>
                          <Discount>{question.discounts[discountInd]}</Discount>
                        </Row>
                      )
                    })}
                  </Flex>
                </Item>
              )
            })}
        </Flex>
        <ButtonWrapper>
          <SubmitBack
            type="submit"
            backgroundColor={"#F50057"}
            onClick={() => {
              handleBack()
            }}
          >
            Back
          </SubmitBack>
          <SubmitNext
            type="submit"
            backgroundColor={"#F50057"}
            onClick={() => {
              handleNext()
            }}
          >
            Pay
          </SubmitNext>
        </ButtonWrapper>
      </Wrapper>
    </WrapperWrap>
  )
}
export default Bill
//  <Item>
//           <Icon>M</Icon>
//           <Flex>
//             <ServiceRow name="MCAT Course" price="2000.00" />
//           </Flex>
//         </Item>

//  <Row>
//         <Description>Flex Package</Description>
//         <DescripPrice>2000</DescripPrice>
//       </Row>
//       <Row>
//         <Description>Flex Package</Description>
//         <Discount>200</Discount>
//       </Row>
//       <Row>
//         <Description>Flex Package</Description>
//         <Discount>200</Discount>
//       </Row>
//       <Row>
//         <Description>Flex Package</Description>
//         <Discount>200</Discount>
//       </Row>
//       <Row>
//         <Description>Flex Package</Description>
//         <Discount>200</Discount>
//       </Row>
//       <Row>
//         <Description>Flex Package</Description>
//         <Discount>200</Discount>
//       </Row>
