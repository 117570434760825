import React from "react"
import { AbsCard } from "../../../molecules/cards.js"
import OverviewTab from "./overviewTab.js"
import DynamicForm from "./formElements"

import { allFields } from "./formFields.js"

const TabCardsView = ({ studentAbsEntries, tabSelected, tabMetaData }) => {
  const { label, key } = tabMetaData
  const relevantFields = allFields.filter(({ belongsTo }) => belongsTo === key)
  if (tabSelected === 0) {
    return <OverviewTab />
  } else {
    return (
      studentAbsEntries &&
      studentAbsEntries.map((item, index) => {
        const { tag } = item
        let shouldRenderItem = (tag && tag.toLowerCase()) === key
        if (shouldRenderItem && relevantFields) {
          return (
            <DynamicForm
              formFields={relevantFields}
              data={item}
              tabLabel={label}
              tabKey={key}
              key={`AbsCard_${tabSelected}_${index}`}
            />
          )
        } else {
          return null
        }
      })
    )
  }
}

export default TabCardsView
