import React, { useState } from "react"
import styled from "styled-components"
import { EssayCardTitle, Label } from "../../../components/typography.js"
import AutoHeightTextArea from "../../../components/elements/autoHeightTextArea.js"
import { Router } from "@reach/router"
//Color Scheme
let primaryColor = "rgb(20,20,20)" //"#f50057"
let primaryPink = "#f50057"
let iconColor = "rgb(240,240,240, 0.95)"

export const CharIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 20px;
  background: ${props => (props.length ? "#1a8917" : "orange")};
  border-radius: 15px;
  padding: 0px 10px;
  float: right;
  color: white;
  font-size: 15px;
  height: 25px;
  line-height: 25px;
  padding: 0 6px;
  text-align: left;
  vertical-align: baseline;
  white-space: normal;
  border: 1px solid rgba(0, 0, 0, 0.15);
  transform: 0.1s background-color, 0.1s border-color, 0.1s color, 0.1s fill;
  margin: 0px 5px;
`

const Indicator = styled(CharIndicator)`
  background: transparent;
  color: black;
  font-size: inherit;

  &:hover {
    color: ${primaryPink};
  }
`
const IndicatorWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  justify-content: flex-end;
`
const SaveButton = styled(Indicator).attrs({
  as: "button",
})`
  font-size: inherit;
`
const Essay = props => {
  const [response, setResponse] = useState("")
  return (
    <div>
      <IndicatorWrap>
        {/* *****Add something to the indicatior */}
        <Indicator className="tooltip">
          <span className="tooltiptext">Help</span>Ugggh Help!
        </Indicator>
        <CharIndicator length={response.length <= 48}>
          {response.length} / 48 Char. Limit
        </CharIndicator>
        {/* *****Activate the button --> Should turn active when ready */}
        <SaveButton>Save Draft</SaveButton>
      </IndicatorWrap>

      <label style={{ color: primaryPink }}>
        Resilience and Coping Mechanism
      </label>
      <EssayCardTitle color={"rgb(60,60,60, 0.8)"}>
        Please describe how you have developed resilience and coping mechanisms.
        How will you utilize these skills to support yourself during your
        medical training in making informed and rational decisions when faced
        with difficult circumstances?
      </EssayCardTitle>

      <AutoHeightTextArea
        //styles
        outline="none"
        border="none"
        width="100%"
        margin="20px 0px"
        color="white"
        fontFamily="inherit"
        font-size="21px"
        placeholder="Enter your response here, but never trust technology; keep your own copy..."
        defaultValue={response}
        onChange={e => {
          setResponse(e.target.value)
        }}
        autoSave
      />
    </div>
  )
}

export default Essay
