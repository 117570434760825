import React from "react"
import styled from "styled-components"

import NumberFormat from "react-number-format"
import { useForm, Controller } from "react-hook-form"

import { Section } from "../sections/sections.js"
import { OvalAnchor } from "../elements/buttons.js"

// const Container = styled.div`
//   position: relative;
//   z-index: 1;
//   background: white;
//   box-sizing: border-box;
//   overflow: auto;
//   height: 100%;
//   width: auto;
//   margin: auto;
//   padding-top: 24px;
// `

const Content = styled.div`
  padding: 0 24px;
  overflow: hidden;
  width: inherit;
`

const ButtonWrapper = styled.footer`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`

const InputWrapper = styled.div`
  position: relative;
  margin-top: 0.25rem;
  height: 100%;
  width: 70%;
  max-width: 700px;
`
export const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 2px;
`
export const Input = styled.input`
  position: relative;
  z-index: 1;
  display: block;
  border: 1px solid rgb(91, 105, 135);
  color: rgb(3, 27, 78);
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 3px;
  font-size: 16px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: left;
  &:active {
    border: 2px dotted rgb(91, 105, 135);
  }
  &:invalid {
    background-color: pink;
  }
`
const Select = styled(Input).attrs({
  as: "select",
})`
  outline: none;
`
const InputSt = styled(NumberFormat)`
  position: relative;
  z-index: 1;
  display: block;
  border: 1px solid rgb(91, 105, 135);
  color: rgb(3, 27, 78);
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 3px;
  font-size: 16px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: left;
  &:active {
    border: 2px dotted rgb(91, 105, 135);
  }
  &:invalid {
    background-color: pink;
  }
  &:placeholder {
    color: #ccc;
  }
`
const SubmitButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  width: 4em;
  padding: 3px 2em;
  text-align: center;
  display: block;
`
const SubmitNext = styled(SubmitButton)`
  &::after {
    content: "  ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
    position: relative;
    left: 5px;
    top: -1px;
  }
  &:hover {
    &::after {
      transform: rotate(-45deg) translate(3px, 3px);
    }
  }
`
// const SubmitBack = styled(SubmitButton)`
//   &::after {
//     content: " ";
//     border: solid;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     padding: 2px;
//     transform: rotate(135deg);
//     position: relative;
//     right: 5px;
//     top: -1px;
//   }
//   &:hover {
//     &::after {
//       transform: rotate(135deg) translate(-3px, -3px);
//     }
//   }
// `
const FormSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.4fr 0.4fr 0.4fr;
  gap: 20px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". .";
`
const Err = styled.span`
  color: #bf1650;
  font-size: 0.8em;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`
const AccountStep = ({ handleNext, handleFormSubmit, formData }) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onBlur", //validate on blur
  })

  const accessLSName = () => {
    if (typeof window !== "undefined") {
      const result = JSON.parse(localStorage.getItem("email"))
      return result && typeof result === "string" && result.length > 0
        ? result
        : ""
    }
  }
  const onSubmit = data => {
    handleFormSubmit(data)
    handleNext(0, data)
  }
  return (
    <Section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <header>
            <h4 style={{ fontSize: "1.125rem" }}>1. Account Info.</h4>
            <p></p>
          </header>
          <FormSection>
            <InputWrapper>
              <Label htmlFor="fullName">Full Name</Label>
              <Input
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                ref={register({ required: true, pattern: /^([^0-9]*)$/ })}
                style={errors.fullName ? { backgroundColor: "pink" } : null}
                defaultValue={formData.fullName || ""}
              />
              {errors.fullName && (
                <Err>Required; Also, make sure you don't include numbers.</Err>
              )}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="contact">Contact</Label>
              <Controller
                as={InputSt}
                format="+# ### ### ####"
                placeholder="+1 123 456 7890"
                defaultValue={formData.contact || ""}
                name="contact"
                control={control}
                type="tel"
                rules={{
                  required: true,
                  pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                }}
                style={errors.contact ? { backgroundColor: "pink" } : null}
              />
              {errors.contact && (
                <Err>
                  10 digits Required! Please make sure you're adding digits.
                </Err>
              )}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="email">Email Address</Label>
              <Input
                type="email"
                placeholder="Enter your E-mail Address"
                name="email"
                ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                defaultValue={formData.email || accessLSName() || ""}
                readOnly={!!accessLSName()}
                style={
                  accessLSName().length > 0
                    ? { backgroundColor: "#ccc" }
                    : errors.email
                    ? { backgroundColor: "pink" }
                    : null
                }
              />
              {errors.email && (
                <Err>Required; Also, make sure you don't include numbers.</Err>
              )}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="dob">Date of Birth</Label>
              <Controller
                as={InputSt}
                format="#### ## ##"
                mask={["y", "y", "y", "y", "m", "m", "m", "d"]}
                placeholder="yyyy-mm-dd"
                defaultValue={formData.dob || ""}
                name="dob"
                control={control}
                style={errors.dob ? { backgroundColor: "pink" } : null}
              />
              {errors.dob && (
                <Err>Required; Also, make sure you don't include numbers.</Err>
              )}
            </InputWrapper>
          </FormSection>
        </Content>

        <Content>
          <h4 style={{ fontSize: "1.125rem" }}>2. Current Residence</h4>
          <p></p>
          <FormSection>
            <InputWrapper>
              <Label htmlFor="address">Home Address</Label>
              <Input
                type="text"
                placeholder="Enter home address"
                name="address"
                ref={register({ required: true })}
                style={errors.address ? { backgroundColor: "pink" } : null}
                defaultValue={formData.address || ""}
              />
              {errors.address && <Err>Don't forget your address!</Err>}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="city">Current City</Label>
              <Input
                type="text"
                placeholder="Enter city name"
                name="city"
                ref={register({ required: true, pattern: /^([^0-9]*)$/ })}
                style={errors.city ? { backgroundColor: "pink" } : null}
                defaultValue={formData.city || ""}
              />
              {errors.city && <Err>You missed your city</Err>}
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="country">Country of Residence</Label>
              <Select
                defaultValue={formData.country || "Canada"}
                ref={register({ required: true })}
                name="country"
              >
                <option value="Australia" name="country">
                  Australia
                </option>
                <option value="Canada" name="country">
                  Canada
                </option>
                <option value="China" name="country">
                  China
                </option>
                <option value="India" name="country">
                  India
                </option>
                <option value="United States" name="country">
                  United States
                </option>
                <option value="United Kingdom" name="country">
                  United Kingdom
                </option>
                <option value="Other" name="country">
                  Other
                </option>
              </Select>
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="postalCode">Postal Code</Label>
              <Input
                type="text"
                placeholder="Enter your postal code"
                name="postalCode"
                ref={register({ required: true })}
                style={errors.postalCode ? { backgroundColor: "pink" } : null}
                defaultValue={formData.postalCode || ""}
              />
              {errors.postalCode && <Err>Please check your postal code.</Err>}
            </InputWrapper>
          </FormSection>
        </Content>
        <ButtonWrapper>
          <SubmitNext type="submit" backgroundColor={"#F50057"}>
            Next
          </SubmitNext>
        </ButtonWrapper>
      </form>
    </Section>
  )
}

export default AccountStep
