import React, { useState, useRef } from "react"
import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  top: -2em;
  width: 100px;
  height: 25px;
  padding: 2%;
  margin: 0 auto;
`

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: ${props => (props.checked === true ? "#06D6A0" : "grey")};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`
export const SwitchLabelClass = styled.label`
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45%;
  height: 90%;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`

export const SwitchToggle = styled.span`
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 45%;
  height: 90%;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  left: ${props => (props.checked === true ? "calc(100% - 2px)}" : "0px")};
  transform: ${props =>
    props.checked === true ? "translateX(-100%)" : "translateX(0)"};
`

export const Switch = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  left: ${props => (props.checked === true ? "calc(100% - 2px)}" : "0px")};
  transform: ${props =>
    props.checked === true ? "translateX(-100%)" : "translateX(0)"};
  &:active ${SwitchLabelClass} {
    width: 60px;
  }
`

const OnOffSwitch = ({ active, handleToggle }) => {
  const switchRef = useRef(null)
  return (
    <Wrapper ref={switchRef}>
      <Switch
        checked={active}
        onChange={() => {
          handleToggle()
          console.log("click")
        }}
        id={"onOffSwitch"}
        type="checkbox"
      ></Switch>
      <SwitchLabel htmlFor={"onOffSwitch"} checked={active}>
        <SwitchToggle checked={active} />
      </SwitchLabel>
    </Wrapper>
  )
}

export default OnOffSwitch
