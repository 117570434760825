import React, { useState } from "react"
import styled from "styled-components"
import StudentLayout from "./studentLayout.js"
import { Section } from "../../sections/sections.js"
import OnOffSwitch from "../../molecules/onOffSwitch.js"
import { CalendarPending } from "../../../images/icons.js"
import ConfidentialLetter from "../../../images/confidentialLetter.js"

const Wrapper = styled.div`
  width: 85%;
  height: auto;
  margin: 50px 10%;
  display: flex;
  flex-flow: column nowrap;
`

const OverviewCardWrapper = styled.div`
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: transparent;
  color: white;
  margin: 10px 20px;
  padding: 0px 16px;
  border: 2px solid white;
  & svg {
    position: absolute;
    top: 50px;
    right: 50px;
  }
  & h3 {
    font-size: 18px;
  }
  & small {
    font-weight: 500;
    font-size: 12px;
  }
`
const Background = styled(ConfidentialLetter)``
const OverviewCard = props => {
  return (
    <OverviewCardWrapper>
      <ConfidentialLetter />
      <h1>{props.title}</h1>
      <small>Your test scores</small>
    </OverviewCardWrapper>
  )
}

const OverviewCardRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
`
const TodoListWrap = styled.div`
  margin: 50px 10px;
  width: inherit;
  & hr {
    1px solid black;
  }

`
const TodoItemWrap = styled.div`
display: flex;
flex-flow; column nowrap;
border-radius: 8px;
&:hover {
  /* box-shadow: 10px 10px 8px #888888; */
  background: rgb(0,0,0, 0.5);
}
& svg {
    stroke: rgb(200, 200, 200) !important;
}
& svg:hover {
  stroke: rgb(200, 200, 200);
}
`

const TodoItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
const TodoItemTime = styled.div`
  width: 150px;
  margin: 0px 20px;
  color: rgb(200, 200, 200);
  font-style: italic;
`
export const TodoList = () => {
  return (
    <TodoListWrap>
      <h3>My Tasks</h3>
      <hr />
      <TodoItemWrap>
        <TodoItem>
          <CalendarPending stroke={"rgb(200,200,200)"} done={false} />
          <TodoItemTime>12th July (3 days)</TodoItemTime>
        </TodoItem>
      </TodoItemWrap>
      <TodoItemWrap>
        <TodoItem>
          <CalendarPending stroke={"rgb(200,200,200)"} done={true} />
          <TodoItemTime>Hello</TodoItemTime>
        </TodoItem>
      </TodoItemWrap>
    </TodoListWrap>
  )
}
const StudentDashboard = props => {
  const [switchStatus, setSwitch] = useState(false)
  return (
    <OverviewCardRow>
      <OverviewCard title="My Career" />
      <OverviewCard title="My Apps" />
      <OverviewCard title="My ABS" />
      <OverviewCard title="My Essays" />

      <TodoList />
    </OverviewCardRow>
  )
}

export default StudentDashboard
