import React from "react"
import styled, { isStyledComponent } from "styled-components"
import DynamicForm from "./formElements.js"
import {
  volunteerFields,
  employmentFields,
  extraCurrFields,
  awardsFields,
  researchFields,
  otherFields,
} from "./formFields.js"

import TodoList from "../../../molecules/lists/todo.js"
import TimeLine from "../../../molecules/lists/timeline.js"
// import { TodoList } from "../dashboard.js"

const BoxWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  margin-top: 2em;
`
const Box = styled.div`
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  margin: 10px;
  font: 22px;
  background: white;
  opacity: 0.95;
  border-radius: 8px;
`
const ListWrappers = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row no-wrap;
  justify-content: space-around;
`

const Timeline = () => {}
const OverviewTab = ({ data, tabLabel }) => {
  return (
    <>
      <BoxWrapper>
        <Box />
        <Box />
        <Box />
        <Box />
      </BoxWrapper>
      <ListWrappers>
        <TimeLine />
        <TodoList />
      </ListWrappers>
    </>
  )
}
export default OverviewTab
