import React from "react"
import styled from "styled-components"
import { CheckedRight } from "../elements/checkmark.js"

const PaymentsWrap = styled.div`
  display: flex;
  width: 70%;
  height: auto;
  min-height: 3em;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgb(26, 28, 10);
  position: relative;
  top: 50px;
`
const StepWrap = styled.span`
  display: inline-flex;
  flex-flow: column nowrap;
  height: auto;
  margin: 0 0;
  text-align: center;
  align-items: center;
  width: auto;
  background-color: ${props =>
    props.active ? "rgb(236,108,149)" : "transparent"};
  width: 100%;
  padding: 16px;
  color: white;
`
// const ProgressBar = styled.div`
//   display: inline-block;
//   width: 35%;
//   background: #ccc;
//   height: 1px;
//   position: relative;
//   top: -9px;
// `
// const ProgressStatus = styled.div`
//   display: block;
//   width: ${props => (props.progress ? props.progress.toString() + "%" : "0%")};
//   background-image: linear-gradient(-325deg, #00b712 35%, #5aff15 95%);
//   background-color: #00b712;
//   height: 1px;
//   transition: width 0.5s ease-out;
// `
const StepLabel = styled.div`
  margin-top: 5px;
`
const Small = styled.small`
  font-size: 12px;
  font-weight: 600;
  color: rgb(255, 255, 255, 0.6);
  letter-spacing: -0.004em;
  display: inline-block !important;
`

const PaymentSteps = ({ stage, callback }) => {
  const handleClick = (e, step) => {
    e.preventDefault()
    callback(step)
  }
  return (
    <PaymentsWrap>
      <StepWrap
        onClick={e => handleClick(e, 0)}
        active={stage > 0}
        style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}
      >
        <div>
          <CheckedRight active={stage > 0} scale={"scale(0.5)"} />

          <Small>STEP 1</Small>
        </div>
        <StepLabel>Enter Account Details</StepLabel>
      </StepWrap>
      {/* <ProgressBar>
        <ProgressStatus progress={stage >= 1 ? 100 : 0} />
      </ProgressBar> */}
      <StepWrap onClick={e => handleClick(e, 1)} active={stage > 1}>
        <div>
          <CheckedRight active={stage > 1} scale={"scale(0.5)"} />
          <Small>STEP 2</Small>
        </div>
        <StepLabel>Service Details</StepLabel>
      </StepWrap>

      {/* <ProgressBar>
        <ProgressStatus progress={stage >= 2 ? 100 : 0} />
      </ProgressBar> */}
      <StepWrap
        onClick={e => handleClick(e, 2)}
        style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}
        active={stage > 2}
      >
        <div>
          <CheckedRight active={stage > 2} scale={"scale(0.5)"} />
          <Small>STEP 3</Small>
        </div>
        <StepLabel>Confirm Order (Pre-Tax)</StepLabel>
      </StepWrap>
    </PaymentsWrap>
  )
}

export default PaymentSteps
