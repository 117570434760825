import React from "react"
import styled from "styled-components"

const Svg = styled.svg`
  &:active {
    fill: #f50057;
  }
`
const ConfidentialLetter = props => {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/Svg"
      viewBox="0 0 667.9 568.3"
      width="66.79"
      height="56.83"
    >
      <path
        d="M421.1 77.4c-6.3 13-17.9 22.8-30 30.7a190.2 190.2 0 01-41.3 20.2l-6 2h-86.6l-1.6-2a35.2 35.2 0 01-7.2-25.2 29.3 29.3 0 0121.8-24.9c11.5-2.4 24.6 4.4 28.1 15.7 2-21.7 4.1-44.3 15.7-62.7A66.4 66.4 0 01362 .3a60.6 60.6 0 0133.5 6.2c1 .4 2 1 2.8 1.5a56 56 0 0116.2 13.7 54.1 54.1 0 016.6 55.7z"
        fill="#f2f2f2"
      />
      <path
        d="M398.3 8a1.5 1.5 0 01-.6 1.3 317 317 0 00-90.3 119 71 71 0 00-.9 2h-4l.9-2q5.7-13.2 12.5-26a321.8 321.8 0 0135-51.6 317 317 0 0144.2-44 2 2 0 01.4-.2 2 2 0 012.8 1.5z"
        fill="#fff"
      />
      <path
        d="M400 128.3H19a18.9 18.9 0 00-9.4 2.5 18.5 18.5 0 00-2 1.3A19 19 0 000 147.3v198a19 19 0 0019 19h381a19 19 0 0019-19v-198a19 19 0 00-19-19zm17 217a17 17 0 01-17 17H19a17 17 0 01-17-17v-198a17 17 0 0111.8-16.2h.4a17 17 0 014.8-.8h381a17 17 0 0117 17z"
        fill="#3f3d56"
      />
      <path
        d="M537.2 386.6a10.7 10.7 0 002-16.4l6-71.2-17.2 2-4.6 69.2a10.8 10.8 0 0013.8 16.4zM476.3 556.4h-12.2l-5.9-47.2h18.1v47.2z"
        fill="#ffb8b8"
      />
      <path
        d="M455.3 553H479v14.8h-38.6a14.9 14.9 0 0115-14.9z"
        fill="#2f2e41"
      />
      <path fill="#ffb8b8" d="M563.3 556.4h-12.2l-5.9-47.2h18.1v47.2z" />
      <path
        d="M542.3 553H566v14.8h-38.6a14.9 14.9 0 0115-14.9zM476.5 527.2a5 5 0 01-.4 0l-17.3-1.5a5 5 0 01-4.5-5.7l26.3-162.8 1.7-22.9a5 5 0 014-4.5l26.4-5.8a5 5 0 015.2 2l6.4 9.5a110.4 110.4 0 008.3 10.5 56.5 56.5 0 0113 35.7 126.6 126.6 0 002 18.6l21.3 114.8a5 5 0 01-4.4 5.7l-16.5 1.3a5 5 0 01-5.3-3.7l-29.2-108.5a1 1 0 00-2 0l-30.1 113.5a5 5 0 01-4.9 3.8z"
        fill="#2f2e41"
      />
      <circle cx="522.7" cy="225.7" r="24.6" fill="#ffb8b8" />
      <path
        d="M481 338l-.5-35c-1.8-22.5 8.1-34.3 16.8-40.2a20 20 0 0115.4-3L532 264a20.4 20.4 0 0113 9.3 19 19 0 012.2 15 20.6 20.6 0 01-8.4 11.5 45 45 0 00-17.8 31.6v.4z"
        fill="#f50057"
      />
      <path
        d="M541.5 312h-17a4.5 4.5 0 01-4.5-4.6V278a13 13 0 1126 0v29.5a4.5 4.5 0 01-4.5 4.5z"
        fill="#f50057"
      />
      <path
        d="M427.4 317.5a11 11 0 01-16.5.5c-9.7-10.2 6.2-25.5 15.9-15.3a10.7 10.7 0 011 1.1l49-1.2 5.8-10.7 18 7c-3.5 5.4-7.4 26.4-16.4 25.4-1.2 0-55.6-6.7-56.8-6.8z"
        fill="#ffb8b8"
      />
      <path
        d="M493 309.4l-13.7-10a4.5 4.5 0 01-1-6.4l17.5-23.7a13 13 0 1121 15.3l-17.5 23.8a4.5 4.5 0 01-6.3 1z"
        fill="#f50057"
      />
      <path
        d="M527.6 238.5a9.1 9.1 0 00-1.6-10.2c-4.6-4.6-11.8-1.5-18-2-10.4-.8-17.5-13.2-14.4-23.1s14.1-16.2 24.5-15.5 19.9 7.1 26.1 15.4c4.3-6.9 14.9-7.7 21.7-3.2s10.3 12.6 11.8 20.6 1.2 16.2 2.4 24.2c3 19.5 15.6 37.9 33.8 45.5s41.5 2.7 53.4-13a86.8 86.8 0 01-54.2 91.1 55 55 0 01-31.1 3.8c-16.8-3.5-29.9-17.8-35.5-34s-5-33.9-2.3-50.8c1.7-10.4 3.6-22.7-4-30a18.2 18.2 0 00-7.3-3.8 9.8 9.8 0 01-5.6-14.4l.3-.6z"
        fill="#2f2e41"
      />
      <path
        d="M615.8 568.3h-228a1 1 0 010-2h228a1 1 0 110 2zM209 246.2a9 9 0 01-4.3-1L5.7 135.5a1 1 0 011-1.8l199 109.6a7 7 0 006.7 0l202.3-106.7a1 1 0 11.9 1.8L213.3 245.2a9 9 0 01-4.2 1zM63.6 314.3h-28a8 8 0 010-16h28a8 8 0 010 16zM96.6 341.3h-61a8 8 0 010-16h61a8 8 0 010 16z"
        fill="#3f3d56"
      />
      <circle cx="209" cy="246.3" r="32" fill="#f50057" />
    </Svg>
  )
}

export default ConfidentialLetter
