import React from "react"
import styled from "styled-components"
import { EssayCard } from "../../molecules/cards.js"

const ApplicationsView = props => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5].map(item => (
        <EssayCard title="Resilience and Coping Mechanism" to />
      ))}
    </>
  )
}

export default ApplicationsView
