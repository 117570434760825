import React from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { isLoggedIn } from "../../services/auth"
import { navigate } from "gatsby"

import { signupUser } from "../../services/apiRequests.js"
let flexContainerSt = {
  maxWidth: "1200px",
  position: "relative",
  padding: "65px 0 64px",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  margin: "auto",
  color: "#031b4e",
}
let containerSt = {
  position: "relative",
  zIndex: "1",
  backgroundColor: "rgb(255,255,255)",
  boxSizing: "border-box",
  border: "1px solid rgba(91, 105, 135, 0.22)",
  borderRadius: "3px",
  marginTop: "10vh",
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.25rem 0.25rem",
  overflow: "auto",
  paddingTop: "24px",
  paddingBottom: "24px",
  height: "100%",
  width: "480px",
}
let mainContentSt = {
  width: "100%",
  overflow: "hidden",
  paddingLeft: "24px",
  paddingRight: "24px",
}
let btnContainerSt = {
  display: "flex",
  justifyContent: "center",
}
let btnSt = {
  appearance: "none",
  margin: "16px 5px 0px",
  overflow: "visible",
  fontWeight: "600",
  color: "rgb(0, 97, 235)",
  textTransform: "none",
  display: "inline-block",
  position: "relative",
  padding: "0px 1rem",
  backgroundColor: "transparent",
  verticalAlign: "middle",
  cursor: "pointer",
  borderRadius: "3px",
  transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
  lineHeight: "44px",
  height: "48px",
  minWidth: "40px",
  maxWidth: "none",
  fontSize: "16px",
  textDecoration: "none",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderWidth: "2px",
  borderColor: "rgb(18, 124, 67)",
  width: "100%",
}
let btnSecondarySt = Object.assign({}, btnSt)
// let btnPrimarySt = Object.assign({}, btnSt, {
//   color: "white",
//   backgroundColor: "rgb(18, 124, 67)",
// })
let footerSt = { paddingLeft: "24px", paddingRight: "24px" }
let footerParaSt = { margin: "16px 0 0", fontSize: "14px", lineHeight: "1.5" }

let inputContainerSt = {
  position: "relative",
  marginTop: "0.25rem",
  height: "100%",
  minWidth: "250px",
  maxWidth: "700px",
}
let labelSt = {
  letterSpacing: "inherit",
  color: "inherit",
  textTransform: "inherit",
  opacity: "inherit",
  fontWeight: "600",
  fontSize: "0.875rem",
}
let inputSt = {
  position: "relative",
  zIndex: "1",
  display: "block",
  border: "1px solid rgb(91, 105, 135)",
  color: "rgb(3, 27, 78)",
  width: "100%",
  height: "48px",
  padding: "0 16px",
  backgroundColor: "#fff",
  transition: "all .25s cubic-bezier(.645,.045,.355,1)",
  borderRadius: "3px",
  fontSize: "16px",
  WebkitWritingMode: "horizontal-tb !important",
  textRendering: "auto",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textAlign: "start",
}
// let inputSt_Active = Object.assign({}, inputSt, {
//   border: "2px dotted rgb(91, 105, 135)",
// })
// const Err = styled.span`
//   color: #bf1650;
//   font-size: 0.8em;
//   &::before {
//     display: inline;
//     content: "⚠ ";
//   }
// `
const Signup = () => {
  const { register, handleSubmit } = useForm()
  const createAccount = userObject => {
    userObject.email = userObject.email.toLowerCase()
    signupUser(userObject)
  }

  const onSubmit = data => {
    if (data.password === data.verifyPassword) {
      createAccount(data)
      navigate("/app/login")
    } else {
      alert("Your passwords don't match. Please also double check your email.")
    }
  }
  //UX: Create another component for signup that's related to sales.
  if (isLoggedIn() && typeof window !== "undefined") {
    window.history.go(-1)
    return
  } else {
    return (
      <div style={flexContainerSt}>
        <div style={containerSt}>
          <div style={mainContentSt}>
            <h4 style={{ fontSize: "1.125rem" }}>Creating a NEW Account</h4>
            <p>
              You're going to create a new account. If you already have an
              account, you should Log In.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginBottom: "0.5rem" }}>
                <label htmlFor="firstName" style={labelSt}>
                  First Name
                </label>
                <div style={inputContainerSt}>
                  <input
                    style={inputSt}
                    type="text"
                    placeholder="What's Your First Name?"
                    name="firstName"
                    ref={register({ required: true, maxLength: 25 })}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <label htmlFor="lastName" style={labelSt}>
                  Last Name
                </label>
                <input
                  style={inputSt}
                  type="text"
                  placeholder="What's Your First Name?"
                  name="lastName"
                  ref={register({ required: true, maxLength: 25 })}
                />
              </div>
              <div style={inputContainerSt}>
                <label htmlFor="email" style={labelSt}>
                  Email Address*
                </label>
                <input
                  style={inputSt}
                  type="text"
                  placeholder="Enter your e-mail address (preferrably Gmail!)"
                  name="email"
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <label htmlFor="phoneNumber" style={labelSt}>
                  Contact Number
                </label>
                <input
                  style={inputSt}
                  type="tel"
                  placeholder="Enter your cellular number"
                  name="phoneNumber"
                  ref={register({
                    required: true,
                    minLength: 6,
                    maxLength: 12,
                  })}
                />
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <label htmlFor="password" style={labelSt}>
                  Password
                </label>
                <input
                  style={inputSt}
                  type="password"
                  placeholder="Enter your password and remember it!"
                  name="password"
                  ref={register({ required: true, minLength: 6 })}
                />
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                <label htmlFor="verifyPassword" style={labelSt}>
                  Re-enter Password
                </label>
                <input
                  style={inputSt}
                  type="password"
                  placeholder="Enter your password and remember it!"
                  name="verifyPassword"
                  ref={register({ required: true, minLength: 6 })}
                />
              </div>
              <div style={btnContainerSt}>
                <button style={btnSecondarySt}>Sign Up</button>
              </div>
            </form>
          </div>
          <footer style={footerSt}>
            <p style={footerParaSt}>
              By signing up you agree to the Terms of Service and Privacy Policy
            </p>
          </footer>
        </div>
      </div>
    )
  }
}

export default Signup
