import React from "react"
import styled, { keyframes } from "styled-components"
import { MainWrap, Wrapper, ListRow, RowText, Flex } from "./styles"

/* PULSING INDICATOR */
const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(139, 244, 87, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(139, 244, 87, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(139, 244, 87, 0);
  }
`
const StatusWrapper = styled.div`
  width: 20%;
  height: auto;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StatusInner = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: scale(1);
  background: rgba(139, 244, 87, 1);
  box-shadow: 0 0 0 0 rgba(139, 244, 87, 1);
  animation: ${pulse} 2s infinite;
`
const Status = () => (
  <StatusWrapper>
    <StatusInner />
  </StatusWrapper>
)

const RowText2 = styled(RowText)`
  width: 250px;
`
const TimeLine = () => {
  return (
    <MainWrap>
      <Flex>
        <h3>My Timeline</h3>
      </Flex>
      <hr />
      <Wrapper>
        <ListRow>
          <Status></Status>
          <RowText2>12th July (3 days)</RowText2>
        </ListRow>
      </Wrapper>
      <Wrapper>
        <ListRow>
          <Status />
          <RowText2>12th July (3 days)</RowText2>
        </ListRow>
      </Wrapper>
    </MainWrap>
  )
}

export default TimeLine
