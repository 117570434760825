import React, { useContext } from "react"
import styled from "styled-components"
import { handleLogin, isLoggedIn } from "../../services/auth"
import { AuthContext } from "../layout.js"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"

import { OvalAnchor } from "../elements/buttons.js"
import LoginSvg from "../../images/login.svg"

const Container = styled.div`
  position: relative;
  z-index: 1;
  background: white;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  width: auto;
  margin: auto;
  padding-top: 24px;
`
const Content = styled.section`
  padding: 0 24px;
  overflow: hidden;
  width: inherit;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`
const Footer = styled.footer`
  padding: 0 24px;
`
const FooterPara = styled.p`
  font-size: 14px;
  line-height: 1.5;
`
const InputWrapper = styled.div`
  position: relative;
  margin-top: 0.25rem;
  height: 100%;
  min-width: 250px;
  max-width: 700px;
`
const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
`
const Input = styled.input`
  position: relative;
  z-index: 1;
  display: block;
  border: 1px solid rgb(91, 105, 135);
  color: rgb(3, 27, 78);
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 3px;
  font-size: 16px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: left;
  &:active {
    border: 2px dotted rgb(91, 105, 135);
  }
`
const Err = styled.span`
  color: #bf1650;
  font-size: 0.8em;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`
const SubmitButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  padding: 1em 2em;
`

const LoginForm = ({ location }) => {
  const [loginStatus, updateLogin] = useContext(AuthContext)
  const { register, handleSubmit, errors } = useForm()
  // const { from } = location.state || { from: { pathname: "/" } }

  const onSubmit = data => {
    authenticateLogin(data)
  }

  const authenticateLogin = data => {
    data.email = data.email.toLowerCase()
    handleLogin(data, (errMessage, success) => {
      if (errMessage) {
        alert(errMessage)
      } else {
        updateLogin(true)
        if (isLoggedIn()) {
          window.alert("Is loggedIn")
          //case 1: "/" to "/login" to "profile";
          //case 2: "/profile" -> "/login" -> "/profile"
          //case 3: "/schedule" -> "/login" -> "/schedule"

          if (typeof location.state !== "undefined") {
            let redirect = location.state.redirect
            navigate(redirect, { state: { redirect: "/app/login" } })
          } else {
            navigate("/app/profile")
          }
        }
      }
    })
  }

  return (
    <Container>
      <Content>
        <h4 style={{ fontSize: "1.125rem" }}>Log In</h4>
        <p></p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <Label htmlFor="email">Email Address*</Label>
            <Input
              type="text"
              placeholder="Enter your e-mail address"
              name="email"
              ref={register({ required: true, pattern: /^\S+@\S+$/i })}
            />
            {errors.email && <Err>Gah! This is an invalid email address.</Err>}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="password">Password*</Label>
            <Input
              type="password"
              placeholder="Enter your password and remember it!"
              name="password"
              ref={register({ required: true })}
            />
            {errors.password && <Err>Uh oh! Your password is required.</Err>}
          </InputWrapper>
          <ButtonWrapper>
            <SubmitButton type="submit" backgroundColor={"#F50057"}>
              Log In
            </SubmitButton>
          </ButtonWrapper>
        </form>
      </Content>
      <Footer>
        <FooterPara>
          If you don't already have an account, then
          <Link to="/app/signup">
            <span style={{ color: "rgb(0, 105, 255)" }}> create one here.</span>
          </Link>
        </FooterPara>
      </Footer>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: stretch;
`
const SvgWrapper = styled.div`
  @media (min-width: 100px) {
    visibility: hidden;
  }
  @media (min-width: 700px) {
    visibility: visible;
  }
  width: 50%;
  padding: 10%;
  background-color: rgb(250, 250, 250);
  align-items: stretch;
`
const LoginWrapper = styled.div`
  padding: 50px;
  align-items: stretch;
  z-index: 2;
`
const Img = styled.img`
  display: block;
  width: 80%;
  height: auto;
  margin: auto;
`
const Login = ({ location }) => {
  if (isLoggedIn()) {
    if (typeof location.state !== "undefined") {
      navigate(location.state.redirect)
    } else {
      if (typeof window !== "undefined") {
        window.history.go(-1)
      }
    }
  }
  return (
    <Wrapper>
      <LoginWrapper>
        <LoginForm location={location} />
      </LoginWrapper>
      <SvgWrapper>
        <Img src={LoginSvg} />
      </SvgWrapper>
    </Wrapper>
  )
}

export default Login
