import React from "react"
import styled from "styled-components"

const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 48px;
`
const Select = styled.select`
  position: relative;
  z-index: 1;
  display: block;
  border: 1px solid rgb(91, 105, 135);
  color: rgb(3, 27, 78);
  width: 150px;
  height: 48px;
  padding: 0 16px;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 3px;
  font-size: 16px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: left;
  transform: scale(0.8);
  &:active {
    border: 2px dotted rgb(91, 105, 135);
  }
`
const Form = styled.form`
  width: 22%;
  display: flex;
  margin-left: auto;
  position: relative;
  right: 0;
`

const SelectInput_v1 = ({ options, fields, cb, htmlFor }) => {
  const manageCb = data => {
    cb(data[0], data[1])
  }
  if (options && Array.isArray(options) && options.length > 0) {
    return (
      <Form>
        <Label htmlFor="select">Filter: </Label>
        <Select
          name={htmlFor}
          id={htmlFor}
          onChange={e => manageCb(e.target.value)}
        >
          {options.map((item, i) => (
            <option value={JSON.stringify([i, item])}>{fields[i]}</option>
          ))}
        </Select>
      </Form>
    )
  }
}

export default SelectInput_v1
