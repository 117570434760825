import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Section } from "../sections/sections.js"
import SimpleTabs from "../molecules/tabs.js"
import AppNav from "./studentPages/appNav.js"
import Abs from "./studentPages/abs.js"
import ApplicationsView from "./studentPages/applicationView.js"
import OnOffSwitch from "../molecules/onOffSwitch.js"

import {
  getCurrentEnrolments,
  redirToBilling,
} from "../../services/apiRequests.js"
import { ProfileCourseCard } from "../molecules/cards.js"
import SelectInput_v1 from "../elements/selectInput.js"

const ProfileSection = styled(Section)`
  @media (max-width: 1080px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`
const TabContentWrap = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
`
const NoDataMessage = styled.div`
  margin: auto;
  padding: 200px 0;
`
const Wrapper = styled.div`
  width: 85%;
  height: auto;
  margin: 50px 10%;
  display: flex;
  flex-flow: column nowrap;
`
const Profile = () => {
  //Tab Props
  let fields = ["All", "Active", "Inactive", "Pending", "Completed"]
  let options = ["all", "active", "inactive", "pending", "completed"]
  const [currTab, setTab] = useState(0) //Whatever Tab is clicked.
  const [currentEnrolments, setCurrent] = useState([]) //All Data
  const [currEnrollmentsFilter, setFilter] = useState("") //Whatever filter is clicked
  const [updateCurr, setUpdate] = useState(0) //Index to decide if data should be fetched.
  const [switchStatus, setSwitch] = useState(false)
  const fetchData = (tabIndex, data) => {
    setUpdate(tabIndex) //To allow fetch via useEffect ^
    setFilter(data) //To save whatever filter we're on.
  }

  const updateTabData = async () => {
    //Don't update data if "Curr" is -1.
    if (updateCurr !== -1) {
      await getCurrentEnrolments(options[currEnrollmentsFilter], data => {
        setCurrent(data)
      })
      //Setting this, so we avoid sideEffect looping till infinity.
      setUpdate(-1)
    }
  }

  useEffect(() => {
    updateTabData()
  }, [currentEnrolments, currEnrollmentsFilter, updateCurr])

  //Format Date in data;
  const formatDate = date => {
    let d = new Date(date)
    var year = d.getFullYear()
    var month = (1 + d.getMonth()).toString()
    month = month.length > 1 ? month : "0" + month
    var day = d.getDate().toString()
    day = d.length > 1 ? day : "0" + day
    return month + "/" + day + "/" + year
  }

  return (
    <div
      style={{
        display: "flex",
        "flex-flow": "row nowrap",
      }}
    >
      <ProfileSection>
        <ApplicationsView />
      </ProfileSection>
    </div>
  )
}
export default Profile

// {/* <Section>
//         <SimpleTabs
//           tabs={[
//             { label: "My Services" },
//             { label: "My Future" },
//             { label: "My Account" },
//           ]}
//           handleTabs={data => {
//             setUpdate(data)
//             setTab(data)
//           }}
//         />
//         <SelectInput_v1
//           fields={fields}
//           options={options}
//           htmlFor={"filterServices"}
//           cb={fetchData}
//         />
//         <button
//           onClick={() => {
//             redirToBilling()
//           }}
//         >
//           To Billing!
//         </button>

//         {/* 1. SHOW THIS WHEN TAB[0] IS CLICKED */}

//         {/* Logic: Don't show anything unless Tab[0] is clicked */}
//         {/* Logic: Render Cards if Data is present && approp.; else show the 'NoDataMessage' */}
//         {currTab === 0 ? (
//           currentEnrolments && currentEnrolments.length > 0 ? (
//             <TabContentWrap>
//               {currentEnrolments.map((item, index) => {
//                 if (
//                   currEnrollmentsFilter === "0" ||
//                   item.serviceActive === options[currEnrollmentsFilter]
//                 ) {
//                   return (
//                     <ProfileCourseCard
//                       key={"profileCourseCard" + index}
//                       status={
//                         currEnrollmentsFilter === "0"
//                           ? item.serviceActive
//                           : fields[currEnrollmentsFilter]
//                       }
//                       serviceName={item.serviceName}
//                       startDate={formatDate(item.startDate)}
//                       endDate={formatDate(item.startDate)}
//                     />
//                   )
//                 }
//               })}
//             </TabContentWrap>
//           ) : (
//             <NoDataMessage>
//               Nothing to show: You haven't enrolled in any of our services
//             </NoDataMessage>
//           )
//         ) : null}

//         {/* 2. SHOW THIS WHEN TAB[0] IS CLICKED */}
//         {currTab === 1 ? (
//           <NoDataMessage>
//             Coming Soon! We're working on creating a "Google-Maps"-like career
//             planning app.!
//           </NoDataMessage>
//         ) : null}
//       </Section> */}
