import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  TitleTextArea,
  InputField,
  Label,
  Hr,
  // DateWrapper,
  CharIndicator,
  AbsCard,
  DynamicSubmitBtn,
} from "../../../molecules/cards.js"
import { useForm, Controller } from "react-hook-form"
import {
  saveAbsEntry,
  createNewAbsEntry,
} from "../../../../services/apiRequests.js"

const FieldError = styled.span`
  color: #bf1650;
  font-size: 0.8em;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`

export const Checkbox = () => {}

const DynamicForm = ({ data, formFields, tabLabel, tabKey }) => {
  const { handleSubmit, errors, control, watch, register } = useForm()
  const [cardClicked, setClick] = useState(false)
  const [showLoader, setLoader] = useState(false)
  const onSubmit = formData => {
    console.log(formData)
    const responseCallback = (err, success) => {
      setLoader(false)
      if (err && !success) {
        throw new Error("Couldn't save changes")
      } else {
        setClick(false)
      }
    }

    setLoader(true)
    let isNewCard = !data["_id"]
    if (isNewCard) {
      formData.tag = tabKey
      console.log(formData)
      // createNewAbsEntry("userid=fjsdnfjdnjs", formData, responseCallback)
    } else {
      console.log("not new card")
      console.log(formData)
      saveAbsEntry(data["_id"], formData, responseCallback)
    }
  }
  // const onSubmit = data => console.log(data)
  console.log(errors)
  return (
    <AbsCard
      data={data}
      cardClicked={cardClicked}
      setClick={setClick}
      showLoader={showLoader}
      setLoader={setLoader}
      title={data.title}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {formFields &&
          formFields.map((field, index) => {
            const { label, placeholder, rules, limit, type } = field
            if (type === "text") {
              return (
                <div key={label + index}>
                  <Label htmlFor={label}>{label}</Label>
                  <Controller
                    as={InputField}
                    control={control}
                    name={label}
                    type="text"
                    placeholder={placeholder}
                    defaultValue={(data && data[label]) ?? ""}
                    // rules={rules}
                  />
                  {errors[label] && (
                    <FieldError>{errors[label].message}</FieldError>
                  )}
                  <Hr />
                </div>
              )
            } else {
              return null
            }
          })}

        <DynamicSubmitBtn
          display={cardClicked}
          loader={showLoader}
          handleClick={onSubmit}
        />
      </form>
    </AbsCard>
  )
}
export default DynamicForm

{
  /* <Controller
                      as={props => {
                        console.log(props)
                        return (
                          <InputField
                            defaultValue={(data && data[label]) ?? ""}
                            type={"text"}
                            placeholder={placeholder}
                            inputRef={props.ref}
                            value={props.value}
                          />
                        )
                      }}
                      // render={props => {
                      //   let fieldLength = 0
                      //   let watchLabel = watch(label)
                      //   if (watchLabel) {
                      //     fieldLength = watchLabel.length
                      //   }
                      //   console.log(props)
                      //   console.log("props")
                      //   return (
                      //     <>
                      //       <TitleTextArea
                      //         value={props.value}
                      //         onChange={e => {
                      //           console.log(e.target.value)
                      //           props.onChange(e.target.value)
                      //         }}
                      //         inputRef={props.ref}
                      //         placeholder={placeholder}
                      //         defaultValue
                      //       />
                      //       {limit ? (
                      //         <CharIndicator length={fieldLength <= limit}>
                      //           {fieldLength}/{limit}
                      //         </CharIndicator>
                      //       ) : null}
                      //     </>
                      //   )
                      // }}
                      control={control}
                      name={label}
                    /> */
}

{
  /* {limit ? (
                        <CharIndicator length={fieldLength <= limit}>
                          {fieldLength}/{limit}
                        </CharIndicator>
                      ) : null} */
}
