import React from "react"
import styled from "styled-components"
import { CourseCard } from "../../molecules/cards.js"

const CoursesView = props => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5].map(item => (
        <CourseCard title="MCAT" to />
      ))}
    </>
  )
}

export default CoursesView


