import React, { useState, useEffect } from "react"
import { getAbsEntries } from "../../../services/apiRequests"
import styled from "styled-components"

import SimpleTabs from "../../molecules/tabs.js"
import { Section } from "../../sections/sections.js"
import TabContentDisplay from "./tabView/view.js"

//Color Scheme
let primaryColor = "rgb(20,20,20)" //"#f50057"
let primaryPink = "#f50057"
let iconColor = "rgb(240,240,240, 0.95)"
const AddItemWrap = styled.div`
  width: 100%;
  cursor: pointer;
`
const AddItem = styled.div`
  width: auto;
  height: 20px;
  background-color: ${primaryPink};
  border-radius: 15px;
  float: right;
  color: White;
  margin: 16px 0px;
  padding: 8px 20px;
  font-size: 15px;
`
const AbsCardsContainer = styled.div`
  height: auto;
  min-height: 50vh;
  width: 85%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

let tabs = [
  { label: "Overview", key: "overview" },
  { label: "Employment", key: "employment" },
  { label: "Volunteering", key: "volunteering" },
  { label: "Extra-Curricular", key: "extra_curricular" },
  { label: "Awards & Accomplishments", key: "awards_and_accomplishments" },
  { label: "Research", key: "research" },
  { label: "Other", key: "other" },
]
const Abs = () => {
  const [currTab, setTab] = useState(0)
  const [tabData, setData] = useState([])
  useEffect(() => {
    if (typeof window !== undefined) {
      let label = tabs[currTab].label
      getAbsEntries(label, data => {
        if (Array.isArray(data)) {
          setData(data)
        }
      })
    }
  }, [currTab, setTab])

  const addItem = () => {
    let dataClone = tabData.map(x => x)
    dataClone.unshift({
      tag: tabs[currTab].key,
      title: "",
      description: "",
      status: "unsaved",
    })
    setData(dataClone)
  }

  return (
    <>
      <>
        <SimpleTabs
          tabs={tabs}
          handleTabs={data => {
            setTab(data)
          }}
          textColor="white"
        />
        {currTab > 0 ? (
          <AddItemWrap onClick={() => addItem()}>
            <AddItem>Add Item</AddItem>
          </AddItemWrap>
        ) : null}
      </>

      <AbsCardsContainer>
        <TabContentDisplay
          studentAbsEntries={tabData}
          tabSelected={currTab}
          tabMetaData={tabs[currTab]}
        />
      </AbsCardsContainer>
    </>
  )
}
export default Abs

//Employment
//Level of education: 30 chars
//Hours: unlimited
//Full/Part-time - Summer or Academic Year, Before University or After Graduation (Maximum 100 characters):  Character Count: 0
// Responsibilities (Maximum 150 characters): // Character Count: 0
//[Checkbox] Most important activities (top 3 per category) for Ottawa (max. 18 in total).

// 1. Employment
// Specify your education level during employment (i.e., first year of university).
// Indicate if the employment was during the summer or academic year, part-time or full-time, total hours (per week or per month) and the number of years.
// Note your title and briefly describe your responsibilities.

//[field]: Lvl of education (30 Chars. implicit)
//[field]: hours (60 chars. implicit)
//Field: Summer, Academic Year, Before University, or After Graduation (Maximum 100 characters)
//Field:  Responsibilities (Maximum 150 characters)
// const employment = [
//   {
//     field: "text",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "Level of Education",
//     label: "Education",
//     explanation: "",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: null,
//     limitType: null,
//     placeholder: "How much (in hours) did you do it?",
//     label: "Hours",
//   },
//   //{field: "text", "limit": 100, limitType: "chars", placeholder: "When did you do it", label: "",  },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "What did you do there?",
//     label: "Responsibilities",
//   },
//   {
//     field: "checkbox",
//     limit: null,
//     limitType: null,
//     placeholder: "Do you think it's significant?",
//     label:
//       "Most important activities (top 3 per category) for Ottawa (max. 18 in total).",
//   },
// ]
// // 2. Volunteer Activities
// // Specify your education level (i.e., first year of university).
// // Indicate if the activity was during the summer or academic year, the total hours (per week or per month) and the number of years.
// // Provide the location where the volunteer work took place and briefly describe your responsibilities.

// //[field]: Lvl of education (30 Chars. implicit)
// //[field]: hours (60 chars. implicit)
// //Field: Summer, Academic Year, Before University, or After Graduation (Maximum 100 characters)
// //Field:  Responsibilities (Maximum 150 characters)
// const volunteer = [
//   {
//     field: "text",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "In which year did you work happen in?",
//     label: "Level of education",
//     example: "first year of university"
//   },
//   {
//     field: "text",
//     limit: 60,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Hours",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 100,
//     limitType: "chars",
//     placeholder: "When did you do it??",
//     label: "Summer, Academic Year, Before University, or After Graduation",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Responsibilities",
//     example: "",
//   },
// ]

// // Specific Activity Details//
// const Specific_activity_detail = [

//   {
//     field: "text",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "In which year did you work happen in?",
//     label: "Level of education",
//     example: "first year of university"
//   },
//   {
//     field: "text",
//     limit: 60,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Hours",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 100,
//     limitType: "chars",
//     placeholder: "When did you do it??",
//     label: "Summer, Academic Year, Before University, or After Graduation",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Responsibilities",
//     example: "",
//   },

// ]

// // 3. Extracurricular Activities
// // Specify your education level (i.e., first year of university).
// // Indicate if the activity was during the summer or academic year, the total hours (per week or per month) and the number of years.
// // Indicate the type of activity: Individual, team or club activity (i.e., sports, arts, music, student governments, personal activities such as camping).
// // For sports activities, indicate at which level you performed: Recreational, varsity, intramural, provincial, national or international.

// //[field]: Lvl of education (30 Chars.)
// //[field]: hours (60 chars.)
// //Field: Summer or Academic Year (Maximum 100 characters)
// //Field:  Type of Activity/Level of Performance (Maximum 150 characters)
// const ECs = [
//   {
//     field: "text",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "In which year did you work happen in?",
//     label: "Level of education",
//     example: "first year of university",
//   },
//   {
//     field: "text",
//     limit: 60,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Hours",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 100,
//     limitType: "chars",
//     placeholder: "When did you do it??",
//     label: "Summer, Academic Year, Before University, or After Graduation",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "How much did you work?",
//     label: "Type of Activity/Level of Performance",
//     example: "",
//   },
// ]

// // 4.Awards and Accomplishments
// // Identify the type of distinction you received: NSERC, OGS (funds awarded to you, not your supervisor), Dean's honour roll, university entrance scholarship, etc.
// // Specify when they were received (e.g., first year of university), the duration, the qualifications necessary for receipt and the competition implied, if known (e.g., 1,000 applicants for 50 scholarships).

// //[field]: Date received (30 Chars.)
// //[field]: Duration (60 chars.)
// //Field: Qualifications (Maximum 100 characters)
// //Field:  Competition Involved (Maximum 150 characters)

// const Awards = [
//   {
//     field: "Date",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "Date Received",
//     label: "Date Received",
//     example: "first year of university",
//   },
//   {
//     field: "text",
//     limit: 60,
//     limitType: "chars",
//     placeholder: "How long?",
//     label: "Duration",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 100,
//     limitType: "chars",
//     placeholder: "What did you do?",
//     label: "Qualifications",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "Who did you compete with?",
//     label: "Competition Involved",
//     example: "",
//   },
// ]
// // 4. Research
// // Specify your education level (i.e., first year of university) and the title of the project.
// // Indicate the type of publications: Paper, abstract or presentation at a scientific meeting, etc. - accepted or published with a reference (submitted or in preparation).

// //[field]: Required field Level of Education (30 chars)
// //[field]: Project Title (cap 60 chars.)
// //[field]: Type of Publication (Maximum 100 characters)
// //[field]: Required field My role (Maximum 150 characters)
// const Awards = [
//   {
//     field: "text",
//     limit: 30,
//     limitType: "chars",
//     placeholder: "I was in...",
//     label: "Field Level of Education",
//     rules: {required: true}
//     example: "first year of university",
//   },
//   {
//     field: "text",
//     limit: 60,
//     limitType: "chars",
//     placeholder: "What was it?",
//     label: "Project Title",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 100,
//     limitType: "chars",
//     placeholder: "What did you do?",
//     label: "Type of Publication",
//     example: "",
//   },
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "tell 'em what you did.",
//     label: "My role",
//     example: "",
//   },
// ]

// // 5.Other
// // Provide additional details.
// // [field]: Additional Information (Maximum 150 characters)
// const other = [
//   {
//     field: "text",
//     limit: 150,
//     limitType: "chars",
//     placeholder: "The unique or different thing I did was...",
//     label: "My role",
//     example: "",
//   },
// ]
